import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { orange, primary } from 'src/styles/newColors'

export const TextSection = styled.div`
  @media (min-width: ${breakpoints.md}) {
    padding: 0 36px;
    max-width: 302px;
    margin-left: 2rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: 456px;
  }

  @media (min-width: ${breakpoints.xl}) {
    padding: 0 48px;
    max-width: 550px;
    margin-left: 0;
  }

  h1 {
    font-family: 'Citrina', sans-serif;
    color: ${orange.dark};
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    @media (min-width: ${breakpoints.md}) {
      font-size: 28px;
      line-height: 33px;
    }
    @media (min-width: ${breakpoints.lg}) {
      font-size: 40px;
      line-height: 44px;
    }

    @media (min-width: ${breakpoints.xl}) {
      font-size: 48px;
      line-height: 52px;
    }
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${orange.dark};
    @media (min-width: ${breakpoints.lg}) {
      font-size: 16px;
      line-height: 20px;
    }

    @media (min-width: ${breakpoints.xl}) {
      font-size: 20px;
      line-height: 25px;
    }
  }
`

export const FormContainer = styled.div`
  padding: 30px 24px;
  max-width: 100%;
  max-height: 100%;

  @media (min-width: ${breakpoints.md}) {
    padding: 0;
  }

  @media (min-width: ${breakpoints.xl}) {
    max-width: 456px;
    margin: 0 auto;
  }

  h2 {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #fff;

    @media (min-width: ${breakpoints.md}) {
      font-size: 24px;
      line-height: 30px;
    }
    @media (min-width: ${breakpoints.lg}) {
      font-size: 20px;
      line-height: 25px;
    }
  }

  label, a {
    color: #fff;
  }
`

export const FormShape = styled.div`
  background-color: ${primary[500]};
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  max-width: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;

  @media (min-width: ${breakpoints.md}) {
    right: 0;
    height: 100%;
    border-radius: 0;
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    max-width: 50%;
    padding: 40px 45px;
  }

  @media (min-width: ${breakpoints.xl}) {
    padding: 40px 45px;
  }
`
