import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'
import { TextSection, FormContainer, FormShape } from './style'
import SectionComponent from 'src/components/SectionComponent'
import FormWhatsapp, { FormDataExternalPayload } from 'src/components/UI/Forms/FormWhatsapp'
import { sand } from 'src/styles/newColors'
import SuccessView from 'src/components/PayrollSimulator/PayrollSimulatorINSS/components/SuccessView'
import ErrorView from 'src/components/PayrollSimulator/PayrollSimulatorINSS/components/ErrorView'
import { ImageWebpWithSizes } from 'src/components/ImageWebp'
import { API_WHATSAPP } from 'src/config/api/Urls'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const Index = () => {
  const [ isSuccess, setIsSuccess ] = useState(false)
  const [ isError, setIsError ] = useState(false)
  const [ sendDatalayerEvent ] = useDataLayer()

  const onFormSubmit = () => {
    sendDatalayerEvent({
      section: 'm_dobra_01',
      element_action: 'submit',
      element_name: 'Continuar pelo Whatsapp',
      section_name: 'Para continuar, precisamos de algumas informações pessoais',
    })
  }

  const formData: FormDataExternalPayload = {
    templateId: 'fgts_simulacao_start_without_cpf',
    metadata: {
      categoria_conversa: 'DS_Wap_Imobiliário_Campanha',
      matricula: 'Não se aplica',
    },
  }

  return (
    <Layout pageContext={pageContext}>
      {isSuccess && (
        <SectionComponent
          id='antecipacao_fgts_whatsapp'
          minHeight={{ sm: '643px', md: '352px', lg: '607px' }}
        >
          <div className='col-12 col-md-6 col-lg-5 pt-2 pt-lg-3 pt-xl-4'>
            <SuccessView title='Tudo certo com as suas informações!' subTitle='Em alguns minutos, entraremos em contato para continuar sua simulação de antecipação de FGTS pelo WhatsApp.' />
          </div>
          <div className='d-none d-sm-block col-md-6'>
            <ImageWebpWithSizes
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/fgts-whatsapp/image.webp'
              altDescription='Homem deitado no sofá sorrindo enquanto olha para o celular'
              widths={{ md: 256, lg: 376, xl: 376 }}
              className='mx-auto d-block'
            />
          </div>
        </SectionComponent>
      )
      }
      {isError && (
        <SectionComponent
          id='antecipacao_fgts_whatsapp'
          minHeight={{ sm: '643px', md: '352px', lg: '607px' }}
        >
          <div className='col-12 pt-2 pt-lg-3 pt-xl-4'>
            <ErrorView title='Erro ao carregar a página' subTitle='Por favor, tente novamente em alguns minutos.' />
          </div>
        </SectionComponent>
        )}
      {!isError && !isSuccess && (
        <SectionComponent
          id='antecipacao_fgts_whatsapp'
          minHeight={{ sm: '821px', md: '690px', lg: '537px', xl: '537px', xxl: '568px' }}
          bgColor={sand}
        >
          <TextSection className='col-12 col-md-6 offset-xl-1'>
            <h1 className='mb-3'>
              Antecipação <br />Saque-Aniversário <br />FGTS a partir de R$100
            </h1>
            <p>
              Antecipa aí seu FGTS e libere um dinheiro que já é seu. Receba agora até 10 anos do seu saque-aniversário sem parcelas mensais.
            </p>
          </TextSection>
          <FormShape>
            <FormContainer className='col-12 col-md-6'>
              <h2>
                Para continuar precisamos de algumas informações:
              </h2>
              <FormWhatsapp.FormComponent
                invertColors
                onSuccessHandler={() => setIsSuccess(true)}
                onErrorHandler={() => setIsError(true)}
                formData={formData}
                apiUrl={API_WHATSAPP}
                onSubmitHandler={onFormSubmit}
              />
            </FormContainer>
          </FormShape>
        </SectionComponent>
      )}
    </Layout>
  )
}

export default Index
